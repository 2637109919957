<template>
  <v-form>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-toolbar-title>Business Partner Master Data</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="flatClose"
          icon
          to="/business-partners/item-master-data"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- end of toolbar 1 -->

      <!-- start of toolbar 2 -->
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-btn
          class="mr-1"
          outlined
          color="toolbarIcon"
          text
          @click="sendData"
          :loading="$store.state.loader"
        >
          <v-icon
            left
            dark
          >mdi-content-save</v-icon>Submit
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-1"
          outlined
          color="toolbarIcon"
          text
          to="/business-partners/item-master-data"
        >
          <v-icon
            left
            dark
          >mdi-eye</v-icon>All BP
        </v-btn>
      </v-toolbar>
      <!-- end of toolbar 2 -->
      <v-card-text>
        <v-row dense>
          <v-col cols="2">
            <v-autocomplete
              dense
              outlined
              v-model="CardType"
              :items="cardOptions"
              label="Bp Category"
              item-text="name"
              item-value="value"
              @change="getSeries(CardType)"
            ></v-autocomplete>
          </v-col>

          <v-col cols="2">
            <v-autocomplete
              dense
              outlined
              v-model="Series"
              :items="seriesData"
              label="Num Series"
              item-text="SeriesName"
              item-value="id"
              @change="setDocNumber(Series)"
            ></v-autocomplete>
          </v-col>

          <v-col cols="2">
            <v-text-field
              :filled="manualNum? false: true"
              :readonly="manualNum? false: true"
              outlined
              dense
              label="Code"
              v-model="record.CardCode"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              outlined
              dense
              label="Name"
              v-model="record.CardName"
            ></v-text-field>
          </v-col>
          <!-- 
          <v-col cols="4">
            <v-text-field outlined dense label="Foreign Name" v-model="record.CardFName"></v-text-field>
          </v-col> -->

          <v-col cols="4">
            <v-autocomplete
              outlined
              dense
              v-model="record.GroupCode"
              :items="bpGroups"
              item-text="GroupName"
              item-value="GroupCode"
              label="Group"
            ></v-autocomplete>
          </v-col>

          <v-col cols="4">
            <v-autocomplete
              outlined
              dense
              v-model="record.Currency"
              :items="currencies"
              item-text="currency"
              item-value="code"
              label="Currency"
            ></v-autocomplete>
          </v-col>

          <v-col cols="4">
            <v-text-field
              outlined
              dense
              label="Tax ID"
              v-model="record.TaxId"
            ></v-text-field>
          </v-col>

          <!-- tabs start here -->
          <v-tabs
            background-color="accent"
            color="white"
          >
            <v-tab>General</v-tab>
            <v-tab>Payment Terms</v-tab>
            <v-tab>Accounting</v-tab>
            <v-tab>Distribution</v-tab>
            <v-tab>Location</v-tab>
            <v-tab>Properties</v-tab>
            <!-- v-tab-items -->

            <!-- general -->
            <v-tab-item>
              <v-row
                class="pt-3"
                dense
              >
                <!-- col md 3 -->
                <v-col cols="4">
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        outlined
                        dense
                        label="Tel 1"
                        v-model="record.Phone1"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        outlined
                        dense
                        label="Tel 2"
                        v-model="record.Phone2"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        outlined
                        dense
                        label="Mobile Phone"
                        v-model="record.Cellular"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        outlined
                        dense
                        label="Email"
                        v-model="record.E_Mail"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- end -->

                <!-- col md 4 -->
                <v-col cols="4">
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        outlined
                        dense
                        label="Contact Person"
                        v-model="record.contactPerson"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-autocomplete
                        outlined
                        dense
                        v-model="record.SlpCode"
                        :items="employees"
                        item-text="SlpName"
                        item-value="id"
                        label="Sales Employee"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        dense
                        outlined
                        @click="openRegionModal"
                        v-model="territory.descript"
                        label="Territory"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-autocomplete
                        dense
                        outlined
                        v-model="record.CmpPrivate"
                        :items="bpTypes"
                        item-text="name"
                        item-value="value"
                        label="Business Partner Type"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- end -->
              </v-row>
            </v-tab-item>
            <!-- end of general -->

            <!-- start of payment terms -->
            <v-tab-item>
              <v-row
                class="pt-3"
                dense
              >
                <v-col cols="4">
                  <v-autocomplete
                    dense
                    outlined
                    v-model="record.ListNum"
                    :items="priceList"
                    item-text="ListName"
                    item-value="id"
                    label="Price List"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    outlined
                    dense
                    label="Credit Limit"
                    v-model="record.CreditLine"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- end of payment terms -->

            <!-- start of accounting -->
            <v-tab-item>
              <v-row
                dense
                class="pt-3"
              >
                <v-col cols="4">
                  <v-text-field
                    dense
                    outlined
                    label="Account Receivable"
                    v-model="record.DebPayAcct"
                    :append-icon="appendedIcon"
                    @click:append="openAccountModal('r')"
                  ></v-text-field>

                  <v-text-field
                    dense
                    outlined
                    label="Downpayment Clearing Account"
                    v-model="record.DpmClear"
                    :append-icon="appendedIcon"
                    @click:append="openAccountModal('c')"
                  ></v-text-field>

                  <v-text-field
                    dense
                    outlined
                    label="Downpayment Interim Account"
                    v-model="record.DpmIntAct"
                    :append-icon="appendedIcon"
                    @click:append="openAccountModal('i')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- end of accounting -->

            <!-- start of distribution -->
            <v-tab-item>
              <v-row
                class="pt-3"
                dense
              >
                <v-col cols="4">
                  <v-autocomplete
                    dense
                    outlined
                    v-model="record.ChannCode"
                    :items="channels"
                    item-text="name"
                    item-value="id"
                    label="Channel"
                  ></v-autocomplete>

                  <v-autocomplete
                    dense
                    outlined
                    v-model="record.TierCode"
                    :items="tiers"
                    item-text="name"
                    item-value="id"
                    label="Tier"
                  ></v-autocomplete>

                  <v-checkbox
                    v-model="record.Distributor"
                    label="Distributor"
                    value="Y"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- end of distribution -->

            <!-- start of location -->
            <v-tab-item>
              <v-row class="pt-3">
                <v-col cols="6">
                  <v-row>
                    <v-col cols="12">
                      <GmapAutocomplete @place_changed="setPlace"></GmapAutocomplete>
                    </v-col>
                    <!-- map comes here -->
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <GmapMap
                        :center="center"
                        :zoom="15"
                        style="width:100%;  height: 400px;"
                      >
                        <GmapMarker
                          :key="index"
                          v-for="(m, index) in markers"
                          :position="m.position"
                          @click="center=m.position"
                        ></GmapMarker>
                      </GmapMap>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- end of location -->

            <!-- start of properties -->
            <v-tab-item>
              <v-row
                class="pt-3"
                dense
              >
                <v-col cols="4">
                  <v-data-table
                    :headers="propertyHeaders"
                    :items="record.crd15"
                    item-key="id"
                  >
                    <!-- start of start time template -->
                    <template v-slot:item.QryGroup="{ item }">
                      <v-autocomplete
                        outlined
                        dense
                        v-model="item.QryGroup"
                        :items="item.cqg1"
                        item-text="Name"
                        item-value="id"
                      ></v-autocomplete>
                    </template>
                    <!-- end of start time -->
                  </v-data-table>
                </v-col>
              </v-row>
            </v-tab-item>
            <!-- end of properties -->
          </v-tabs>
        </v-row>

        <!-- start of account sellection modal -->
        <v-dialog
          v-model="accountDialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="accent"
            >
              <v-toolbar-title>
                <v-btn
                  icon
                  dark
                  @click="accountDialog = false"
                >
                  <v-icon dark>mdi-keyboard-backspace</v-icon>
                </v-btn>Select Account
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                icon
                dark
                @click="accountDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-card-title>
                      <v-text-field
                        hide-details
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                      ></v-text-field>
                    </v-card-title>
                    <v-data-table
                      @item-selected="clicked"
                      :items-per-page="itemsPerPage"
                      v-model="selected"
                      :headers="headers"
                      :items="accounts"
                      :single-select="singleSelect"
                      item-key="AcctCode"
                      show-select
                      class="elevation-1"
                      :search="search"
                    ></v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- end of account selection modal -->

        <!-- start of territory dialog -->
        <v-dialog
          v-model="territoryDialog"
          width="650px"
        >
          <v-card>
            <v-toolbar
              color="accent"
              dark
              dense
            >
              <v-toolbar-title>Select Territory</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click.native="territoryDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <!-- territories -->
                <v-col cols="12">
                  <v-treeview
                    color="accent"
                    :activatable="trueValue"
                    :hoverable="trueValue"
                    :selection-type="selectionType"
                    open-all
                    return-object
                    item-text="descript"
                    item-key="id"
                    :items="territories"
                    item-children="children_recursive"
                    :search="search"
                  >
                    <template v-slot:label="{ item }">
                      <div @click="selectItem(item)">
                        <span style="font-weight: bold">{{item.descript}}</span>
                      </div>
                    </template>
                  </v-treeview>
                </v-col>
                <!-- end -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- snackbar -->
        <snackbar ref="snackbar"></snackbar>
        <!-- end of territory dialog -->
      </v-card-text>
    </v-card>
  </v-form>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
    currencies: {
      type: Array,
    },
    itemsData: {
      type: Object,
    },
    pageMode: {
      type: String,
    },
  },
  data: () => ({
    center: { lat: -1.219719, lng: 36.889224 },
    Series: null,
    loader: false,
    manualNum: false,
    seriesData: [],
    CardType: null,
    cardOptions: [
      { name: "Customer", value: "C" },
      { name: "Vendor", value: "S" },
      // { name: "Lead", value: "L" },
    ],
    propertyHeaders: [
      { text: "Name", value: "GroupName" },
      { text: "Property", value: "QryGroup" },
    ],
    bpTypes: [
      { name: "Company", value: "C" },
      { name: "Private", value: "I" },
      { name: "Employee", value: "E" },
    ],
    markers: [],
    places: [],
    currentPlace: null,
    record: {},
    territory: {},
    territoryDialog: false,
    selectionType: "leaf",
    search: null,
    trueValue: true,
    falseValue: false,
    itemsPerPage: 20,
    selected: [],
    singleSelect: true,
    DebPayAcct: null,
    DpmClear: null,
    DpmIntAct: null,
    tab: null,
    accountDialog: false,
    accountField: null,
    appendedIcon: "mdi-dots-horizontal-circle-outline",
    accounts: [],
    priceList: [],
    bpProperties: [],
    employees: [],
    territories: [],
    channels: [],
    checkbox: null,
    tiers: [],
    series: [{ name: "Manual", value: "M" }],
    bpGroups: [],
    headers: [
      { text: "Account Code", value: "AcctCode" },
      { text: "Account Name", value: "AcctName" },
      { text: "Active", value: "ValidFor" },
      { text: "Balance", value: "CurrTotal" },
      { text: "Group Mask", value: "GroupMask" },
    ],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
    itemsData: {
      handler: "setBpMaster",
      immediate: true,
    },
  },
  mounted() {
    this.geolocate();
  },
  methods: {
    setDocNumber(id) {
      const seriesRecord = this.seriesData.find((record) => {
        return record.id === id;
      });

      if (seriesRecord.IsManual === "Y") {
        this.manualNum = true;
        this.record = { ...this.record, CardCode: null };
      } else {
        this.manualNum = false;
        this.record = { ...this.record, CardCode: seriesRecord.NextNumber };
      }
      this.Series = seriesRecord.id;
    },
    geolocate: function () {
      const self = this;
      navigator.geolocation.getCurrentPosition((position) => {
        const marker = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        self.record.Latitude = position.coords.latitude;
        self.record.Longitude = position.coords.longitude;
        self.center = marker;
        self.markers.push({ position: marker });
      });
    },
    setPlace(place) {
      this.currentPlace = place;
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.record.Latitude = this.currentPlace.geometry.location.lat();
        this.record.Longitude = this.currentPlace.geometry.location.lng();
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
    openRegionModal() {
      this.territoryDialog = true;
    },
    selectItem(data) {
      this.territory = data;
      this.territoryDialog = false;
    },
    init(val) {
      if (val.territory) {
        this.territory = val.territory;
      }

      this.record = { ...val };
    },
    setBpMaster(val) {
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },
    clicked($event) {
      const AcctItem = $event.item;
      const activeField = this.accountField;
      if (activeField == "r") {
        this.record.DebPayAcct = AcctItem.AcctCode;
        this.DebPayAcct = AcctItem.id;
      }

      if (activeField == "c") {
        this.record.DpmClear = AcctItem.AcctCode;
        this.DpmClear = AcctItem.id;
      }

      if (activeField == "i") {
        this.record.DpmIntAct = AcctItem.AcctCode;
        this.DpmIntAct = AcctItem.id;
      }

      this.accountDialog = false;
    },
    sendData() {
      if (this.record.CardName == undefined) {
        this.$refs.snackbar.show("Please fill Business partner Name", "red");
      } else {
        this.record.DebPayAcct = this.DebPayAcct;
        this.record.DpmClear = this.DpmClear;
        this.record.DpmIntAct = this.DpmIntAct;
        this.record.Territory = this.territory.id;
        this.record.Series = this.Series;
        if (this.record.Distributor === null) {
          this.record.Distributor = "N";
        }
        this.$store.commit("loader", true);
        this.$emit("data", this.record);
      }
    },
    openAccountModal(data) {
      this.selected = [];
      this.accountField = data;
      this.search = null;
      this.accountDialog = true;
    },
    getTerritories() {
      const self = this;

      this.$store
        .dispatch("get", `/territories`)
        .then((res) => {
          self.territories = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getSalesEmployees() {
      const self = this;
      this.$store
        .dispatch("get", `/employee`)
        .then((res) => {
          self.employees = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getPriceList() {
      const self = this;
      this.$store
        .dispatch("get", `/price_lists`)
        .then((res) => {
          self.priceList = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getAccounts() {
      const self = this;
      this.$store
        .dispatch("get", `/activeGLaccounts`)
        .then((res) => {
          self.accounts = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getChannels() {
      const self = this;
      this.$store
        .dispatch("get", `/channels`)
        .then((res) => {
          self.channels = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getTiers() {
      const self = this;
      this.$store
        .dispatch("get", `/tiers`)
        .then((res) => {
          self.tiers = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getBpProperties() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/bp_properties`)
        .then((res) => {
          if (self.pageMode == "create") {
            self.record.crd15 = res;
          }
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getSeries(val) {
      const self = this;
      let url = "";
      if (val == "C") {
        url = "/form_settings/1";
      } else if (val == "S") {
        url = "/form_settings/2";
      } else {
        url = "/form_settings/4";
      }
      this.$store
        .dispatch("get", `${url}`)
        .then((res) => {
          self.seriesData = res.Series;
          self.Series = res.DfltSeries.id;
          if (res.DfltSeries.IsManual == "Y") {
            self.manualNum = true;
          } else {
            self.manualNum = false;
          }
          self.record.CardCode = res.DfltSeries.NextNumber;
        })
        .catch((err) => {
          console.log(err, "error");
        });

      // get groups
      this.$store
        .dispatch("get", `/bp_groups/${val}`)
        .then((res) => {
          self.bpGroups = res.ResponseData;
          self.record.GroupCode = res.ResponseData[0].GroupCode;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    formSettings() {
      const self = this;
      this.$store
        .dispatch("get", `/form_settings/1`)
        .then((res) => {
          self.seriesData = res.Series;
          self.Series = res.DfltSeries.id;
          if (res.DfltSeries.IsManual == "Y") {
            self.manualNum = true;
          } else {
            self.manualNum = false;
          }
          self.record = { ...this.record, CardCode: res.DfltSeries.NextNumber };
          self.CardType = "C";
        })
        .catch((err) => {
          console.log(err, "error");
        });
    },
    getGroups() {
      const self = this;
      this.$store
        .dispatch("get", `/bp_groups/C`)
        .then((res) => {
          self.bpGroups = res.ResponseData;
          self.record.GroupCode = res.ResponseData[0].GroupCode;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    setDefaults() {
      const self = this;
      this.$store
        .dispatch("get", `/company_details`)
        .then((res) => {
          self.record = {
            ...self.record,
            Currency: res.ResponseData.MainCurncy,
            SlpCode: res.ResponseData.DfltSlp,
            CmpPrivate: "C",
          };
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getGroups();
    //this.setDefaults();
    this.getTerritories();
    this.getSalesEmployees();
    this.getPriceList();
    this.getAccounts();
    this.getChannels();
    this.getTiers();
    this.getBpProperties();
    this.formSettings();
  },
};
</script>